export const environment = {
	baseUrl: 'http://localhost:4200',
	isProd: true,
	webUrl: 'https://desiren.me',
	wsUrl: 'wss://messages.desiren.me',
	messagesUrl: 'https://messages.desiren.me/api',
	postUrl: 'https://posts.desiren.me/api',
	apiUrl: 'https://api.desiren.me/api',
	storeUrl: 'https://store.desiren.me',
	looksUrl: 'https://looks.desiren.me',
	storeOldUrl: 'https://store.prestinly.com',
	hubUrl: 'https://hub.desiren.me/api',
	acceptJs: 'https://jstest.authorize.net/v1/Accept.js',
	acceptJsUI: 'https://jstest.authorize.net/v3/AcceptUI.js',
	paymentsUrl: 'https://api.desiren.me/api',
	pricesUrl: 'https://api.desiren.me/api/prices/manage',
	subscriptionsUrl: 'https://api.desiren.me/api/subscriptions',
	notificationsUrl: 'https://notifications.desiren.me/api/notifications',
	acceptJsConfig: {
		acceptjsUrl: 'https://js.authorize.net/v1/Accept.js',
		apiLoginID: '74cAcqhF3B2',
		clientKey: '5p6B72CW992NQNnMFAa4YLdbAznmLTnLmvgzrD89Et7HSH267eX7vHj7tUHzC2gr',
	},
	firebaseConfig: {
		apiKey: 'AIzaSyDQFPXMcoTNBLK0SBDXJ0iuaLr5MnMSR7g',
		authDomain: 'desiren-d352a.firebaseapp.com',
		projectId: 'desiren-d352a',
		storageBucket: 'desiren-d352a.appspot.com',
		messagingSenderId: '280086692528',
		appId: '1:280086692528:web:e294a1ec510d3b2285b175',
		measurementId: 'G-BCHRNJL2HH',
	},
};
